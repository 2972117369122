import React from 'react';
import Pad from '../../../ui/pad/Pad';

import FeedbackLinks from '../../../modules/forSinglePages/group4/feedback/feedbackLinks/FeedbackLinks';

const Feedback = () => {
  return (
    <Pad>
      <FeedbackLinks />
    </Pad>
  );
};

export default Feedback;
